<script setup lang="ts">
import type { Component as BrComponent, Page } from '@bloomreach/spa-sdk'

const { isMobileMenuOpen } = storeToRefs(useUiState())

defineProps({
  component: {
    type: Object as () => BrComponent,
    required: true,
  },
  page: {
    type: Object as () => Page,
    required: true,
  },
})
</script>

<template>
  <TopNavMenu class="desktop-only" :component :page />
  <MobileNavMenu v-if="isMobileMenuOpen" class="mobile-only" :component :page />
</template>
