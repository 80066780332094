<script lang="ts" setup>
const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  item: {
    type: Object,
    default: {},
  },
  value: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
})

const mappedOptionsList = computed(() =>
  props.item.titleAndExponeaId.map((option) => ({
    value: option.fieldName,
    label: option.label,
  })),
)

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(props.name, undefined, {
  initialValue: props.value,
})
</script>

<template>
  <div class="form-dropdown exponea-form__horizontal">
    <div class="exponea-form__element" :name>
      <SfSelect
        class="exponea-form__element form__select sf-select--underlined"
        :options="mappedOptionsList"
        :required
        @change="handleChange"
        @blur="handleBlur"
      />
    </div>
    <p v-if="errorMessage" class="sf-input__error-message">
      {{ errorMessage }}
    </p>
  </div>
</template>
