<script lang="ts" setup>
const props = defineProps({
  hasSearch: {
    type: Boolean,
    required: true,
  },
  hasContent: {
    type: Boolean,
    required: true,
  },
  showOnDesktop: {
    type: Boolean,
    default: false,
  },
})

const selected = ref('searchResults')

watchEffect(() => {
  if (props.hasContent) selected.value = 'contentSearch'
  if (props.hasSearch) selected.value = 'searchResults'
})

defineExpose({ selected })
</script>

<template>
  <div
    v-if="hasSearch && hasContent"
    class="toggle-bar-wrapper"
    :class="showOnDesktop ? 'grid-container' : 'mobile-only'"
  >
    <div class="toggle-bar">
      <button
        class="toggle-bar__button"
        :class="{ selected: selected === 'searchResults' }"
        @click="selected = 'searchResults'"
      >
        {{ $t('Search results') }}
      </button>
      <button
        class="toggle-bar__button"
        :class="{ selected: selected === 'contentSearch' }"
        @click="selected = 'contentSearch'"
      >
        {{ $t('Info & inspiration') }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.toggle-bar-wrapper {
  background-color: var(--gray-background-color);
}

.toggle-bar {
  display: flex;
  gap: var(--spacer-sm);
  height: 42px;
  padding: 0 var(--spacer-sm);

  &__button {
    height: 100%;
    border: none;
    color: var(--c-text);
    padding: 0 0;
    font-size: var(--font-size--sm);
    font-family: var(--font-family--primary);
    background-color: transparent;
    cursor: pointer;

    &.selected {
      font-weight: var(--font-weight--bold);
      border-bottom: 2px solid var(--cta-pink-color);
      transition-duration: 0.2s;
    }
  }
}
</style>
