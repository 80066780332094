<script lang="ts" setup>
import debounce from '~/utils/debounce'
import { clickOutside } from '~/storefront/components/Sf/utilities/directives/index.js'

import ChevronLeft from '~/storefront/assets/icons/chevron-left.svg'
import SearchIcon from '~/storefront/assets/icons/search.svg'

const emit = defineEmits(['SearchBar:toggle'])

const vClickOutside = clickOutside

const { isDesktop, isMobile } = useDevice()
const router = useRouter()
const isSearchOpen = ref(false)
const searchInputElement = ref()

const searchStore = useSearchStore()
const uiState = useUiState()
const { term } = storeToRefs(searchStore)
const { searchContent, searchSuggest } = searchStore
const { v125019MobileSearchMenuFocussed } = storeToRefs(uiState)
const { toggleMobileMenu } = uiState
const { clearFilters } = useBloomreachDiscoveryStore()
const { $localePath } = useNuxtApp()

const { useAb25019 } = useAbTest()
const { useAb25020 } = useAbTest()

const { ab25019ShowV1 } = useAb25019()
const { sendAbTestEvent, showV0 } = useAb25020()

const MIN_TERM_LENGTH = 1

onMounted(() => {
  if (v125019MobileSearchMenuFocussed.value && searchInputElement.value) {
    searchInputElement.value.focus()?.click()
    v125019MobileSearchMenuFocussed.value = false
  }
})

const showSearch = () => {
  if (!term.value?.length) return

  if (!isSearchOpen.value) {
    isSearchOpen.value = true
    emit('SearchBar:toggle', true)
    document?.documentElement.classList.add('no-scroll', 'no-scroll-safari')
  }
}

const clickInput = () => {
  showSearch()
  if (showV0 && isDesktop) {
    sendAbTestEvent(25020, 'click search-bar')
  }
}

const focusInput = () => {
  showSearch()
  if (isMobile) {
    sendAbTestEvent(25019, 'focus search bar')
  }
}

const hideSearch = () => {
  isSearchOpen.value = false
  searchInputElement.value?.blur()
  emit('SearchBar:toggle', false)
  document?.documentElement.classList.remove('no-scroll', 'no-scroll-safari')

  setTimeout(() => (term.value = ''), 10)
}

const toggleSearch = () => {
  if (isSearchOpen.value) {
    hideSearch()
  } else {
    showSearch()
  }
}

const closeSearch = (event) => {
  if (!event) return
  if (document) {
    const searchResultsEl = document.getElementsByClassName('search')
    const wishlistSelectionEl = document.getElementsByClassName('wishlist-variant-selection-modal')
    const sidebarEl = document.getElementsByClassName('sf-sidebar')

    if (
      !searchResultsEl[0]?.contains(event.target) &&
      !wishlistSelectionEl[0]?.contains(event.target) &&
      !sidebarEl[0]?.contains(event.target) &&
      !event.target?.closest('.sf-sidebar')
    ) {
      hideSearch()
    } else if (ab25019ShowV1) {
      hideSearch()
      toggleMobileMenu()
    }
  } else {
    hideSearch()
  }
}

const submitSearch = async () => {
  if (!term.value) return

  clearFilters()
  handleSearch.cancel()

  if (ab25019ShowV1.value) {
    toggleMobileMenu()
  }

  await router
    .push({
      path: `${$localePath('/catalogsearch/result/')}`,
      query: { q: term.value },
    })
    .catch(() => {})
}

const handleSearch = debounce(async () => {
  if (term.value.length < MIN_TERM_LENGTH) {
    hideSearch()
    return
  }
  showSearch()

  await Promise.all([searchSuggest(term.value), searchContent(term.value)])
}, 500)

const ab25019ClickOutsideHandler = () => {
  if (!ab25019ShowV1.value) closeSearch()
}

// For some reason sometimes only one of the below get called. No reason why
// Causes: https://sqli-nl.atlassian.net/browse/MYJE-7370
onBeforeRouteLeave(hideSearch)
onBeforeUnmount(hideSearch)
</script>

<template>
  <form class="sf-header__search-form" method="get" @submit.prevent="submitSearch">
    <div
      v-click-outside="ab25019ClickOutsideHandler"
      class="sf-search-bar sf-header__search"
      :class="{ 'sf-search-bar--has-term': !!term }"
    >
      <SfButton
        class="sf-button--pure sf-search-bar__close-button"
        :style="{ '--button-background': ab25019ShowV1 ? 'transparent' : 'var(--gray-background-color)' }"
        type="button"
        :aria-label="$t('Close search')"
        data-testid="closeSearchButton"
        @click="closeSearch"
      >
        <ChevronLeft />
      </SfButton>
      <input
        ref="searchInputElement"
        class="sf-search-bar__input"
        type="search"
        :placeholder="isDesktop ? $t('Search for products, ...') : $t('Search for products, categories, ...')"
        :aria-label="$t('Search')"
        name="q"
        autocomplete="off"
        :value="term"
        data-testid="searchInput"
        data-hj-allow
        @input="(e) => (term = e.target.value)"
        @keydown="handleSearch"
        @keydown.tab="hideSearch"
        @keydown.esc="closeSearch"
        @focus="focusInput"
        @click="clickInput"
      />
      <SfButton
        v-if="!!term"
        class="sf-search-bar__button sf-button--pure"
        :aria-label="$t('Search')"
        data-testid="searchButton"
        @click="closeSearch"
      >
        <span class="sf-search-bar__icon">
          <SearchIcon :aria-label="$t('Cancel')" />
        </span>
      </SfButton>
      <SfButton
        v-else
        class="sf-search-bar__button sf-button--pure"
        :aria-label="$t('Open search')"
        @click="toggleSearch"
        @keydown.tab="hideSearch"
      >
        <span class="sf-search-bar__icon">
          <SearchIcon :aria-label="$t('Search')" />
        </span>
      </SfButton>
    </div>
  </form>
</template>

<style lang="scss">
body .sf-search-bar {
  @mixin placeholder {
    &::-webkit-input-placeholder {
      @content;
    }
    &::-moz-placeholder {
      @content;
    }
    &:-ms-input-placeholder {
      @content;
    }
  }
  @mixin iconPosition {
    flex-direction: var(--search-bar-icon-flex-direction, row);
    .sf-search-bar__input {
      --search-bar-input-text-align: center;
      &::placeholder {
        text-align: center;
      }
    }
  }
  box-sizing: border-box;
  position: relative;
  display: var(--search-bar-display, flex);
  align-items: center;
  background: var(--search-bar-background);
  padding: var(--search-bar-padding);
  width: var(--search-bar-width);
  height: var(--search-bar-height, 2rem);
  &__button {
    position: absolute;
    bottom: var(--spacer-xs);
    right: var(--search-bar-button-right, 0);
  }
  &__icon {
    --icon-size: 1.25rem;
    display: var(--search-bar-icon-display, block);
  }
  &__input {
    position: relative;
    flex-grow: 1;
    background: transparent;
    text-align: var(--search-bar-input-text-align);
    width: 100%;
    height: 100%;
    @include font(
      --search-bar-font,
      var(--font-weight--normal),
      var(--font-size--base),
      1.6,
      var(--font-family--secondary)
    );
    @include border(--search-bar-border, 0 0 2px 0, solid, var(--c-dark-variant));
    @include placeholder {
      color: var(--search-bar-placeholder-color, var(--c-gray));
      transition: var(--search-bar-placeholder-transition, color 0.2s ease-in);
      font: inherit;
    }
    &:focus {
      --search-bar-border-color: var(--c-primary);
      @include placeholder {
        color: var(--search-bar-placeholder-color-focus, transparent);
      }
    }
    &::-webkit-search-cancel-button {
      opacity: 0;
    }
    &[type='search'] {
      -webkit-appearance: none;
    }
    &::-ms-clear {
      opacity: 0;
    }
  }
  &--position-center {
    @include iconPosition();
  }
  &--no-icon {
    --search-bar-icon-display: none;
  }
}

.sf-header__search-form {
  flex: 0 0 100%;
}

.sf-search-bar {
  transition: padding 0.25s ease;
  will-change: padding;

  .sf-search-bar__close-button {
    position: absolute;
    left: 0;
    visibility: hidden;
    opacity: 0;
    --button-width: 44px;
    --button-height: 46px;

    --button-padding: 1px;
    --button-border-radius: 0;

    &.bg-ab25019 {
      --button-background: #00ff00;
    }

    @include for-desktop {
      --button-height: 48px;

      &:hover {
        --button-background: var(--primary-color);
      }
    }
  }

  &.sf-search-bar--has-term {
    padding-left: 56px;
    transition: all 0.25s ease;

    .sf-search-bar__close-button {
      visibility: visible;
      opacity: 1;

      &.bg-ab25019 {
        --button-background: transparent;
      }
    }
  }

  &__button {
    position: absolute;
    bottom: 0 !important;
    --button-height: 100%;

    @include for-desktop {
      --button-height: var(--spacer-3rem) !important;
      --search-bar-button-right: var(--spacer-sm);
    }
  }

  &__icon {
    height: var(--spacer-base);
  }

  &__input {
    --search-bar-border-color: var(--gray-dark-accent-color);
    padding: 0 var(--spacer-12);
    border-radius: var(--spacer-2xs);
    outline: none;

    ::placeholder {
      color: var(--black-secondary-color);
      opacity: 1;
    }

    @include for-desktop {
      padding: 0 var(--spacer-xl) 0 var(--spacer-md);
    }

    &:focus {
      --search-bar-border-color: var(--secondary-color) !important;
    }
  }
}
</style>
