<script setup lang="ts">
const router = useRouter()
const localePath = useLocalePath()
const { locale } = useI18n()
const { term, loading, suggestResults, contentResults } = storeToRefs(useSearchStore())

const { useAb25020, useAb25019 } = useAbTest()
const { showV1 } = useAb25020()
const { ab25019ShowV1 } = useAb25019()

defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['SearchBar:hide'])

const customContentSearch = await import(`./contentSearchResults_${locale.value.substring(0, 2)}.json`)

const toggleBar = ref()

const customContentSearchResults = computed(() =>
  customContentSearch.default.filter((item) =>
    item.keywords.some((keyword) => keyword.toLowerCase() === term.value?.toLowerCase()),
  ),
)

const products = computed(() =>
  suggestResults.value?.products?.slice(0, 6 - (customContentSearchResults.value?.length || 0)),
)
const content = computed(() => contentResults.value ?? [])
const suggestions = computed(() => suggestResults.value?.suggestions || [])

const hasSearchResult = computed(() => Boolean(products.value?.length || customContentSearchResults.value?.length))
const hasContentResult = computed(() => Boolean(content.value?.length))
const showSuggestions = computed(
  () =>
    (products.value.length && toggleBar.value?.selected === 'searchResults') ||
    (!products.value.length && toggleBar.value?.selected === 'contentSearch'),
)

const onViewportChanged = () => {
  const viewport = (event?.target || window.visualViewport) as VisualViewport
  document.documentElement.style.setProperty('--viewport-height', `${viewport.height}px`)
}

onMounted(() => {
  window.visualViewport.addEventListener('scroll', onViewportChanged)
  window.visualViewport.addEventListener('resize', onViewportChanged)
  onViewportChanged()
})

onBeforeUnmount(() => {
  document.documentElement.classList.remove('no-scroll', 'no-scroll-safari')
})

onUnmounted(() => {
  suggestResults.value = {
    suggestions: [],
    products: [],
  }
  contentResults.value = []
})

const handleShowSearchResults = async (query: string) => {
  emit('SearchBar:hide')
  document?.documentElement.classList.remove('no-scroll', 'no-scroll-safari')
  await router
    .push({
      path: `${localePath('/catalogsearch/result/')}`,
      query: { q: query },
    })
    .catch(() => {})
}
</script>

<template>
  <div class="sf-search-results">
    <SfMegaMenu
      class="search"
      :class="{ ab25020: showV1, 'top-[75px]': ab25019ShowV1, 'top-[118px]': !ab25019ShowV1 }"
      :visible
      :title="$t('Search results')"
    >
      <transition name="sf-fade" mode="out-in">
        <SfLoader v-if="loading && !hasSearchResult" />
        <div v-else-if="hasSearchResult || hasContentResult" key="results" class="search__wrapper">
          <SearchContentToggleBar ref="toggleBar" :hasSearch="hasSearchResult" :hasContent="hasContentResult" />

          <SfList
            v-if="suggestions.length"
            class="search__suggestions desktop-only"
            :class="{ 'display-block': showSuggestions }"
          >
            <SfListItem v-for="(suggestion, key) in suggestions" :key="key" class="search__suggestions-list-item">
              <SfMenuItem @click.native.prevent="handleShowSearchResults(suggestion.displayText)">
                <template #label><span v-dompurify-html="suggestion.markedText" /></template>
                <template #mobile-nav-icon> &#8203; </template>
              </SfMenuItem>
            </SfListItem>
          </SfList>

          <SearchBarResultsContentSearch
            class="desktop-only"
            :class="{ 'display-block': toggleBar?.selected === 'contentSearch' }"
            :content="content"
            :hasSearch="hasSearchResult"
          />

          <SearchBarResultsProductsSearch
            class="desktop-only"
            :class="{ 'display-block': toggleBar?.selected === 'searchResults' }"
            :products="products"
            :customContentSearchResults="customContentSearchResults"
            :query="term"
            :handleShowSearchResults
          />
        </div>

        <div v-else-if="term.length" key="no-results" class="before-results">
          <p
            class="before-results__paragraph"
            v-html="$t('No products were found for the search query', { query: term || '' })"
          />
        </div>

        <div v-else-if="!term.length && showV1" key="no-term" class="before-results"></div>
      </transition>
    </SfMegaMenu>
  </div>
</template>

<style lang="scss">
.sf-search-results {
  .search__suggestions-list-item > .sf-button {
    font-size: var(--font-size--xs);
  }

  .search__wrapper > desktop-only {
    margin-top: var(--spacer-base);
  }

  .search {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    overflow: scroll;
    transition: height 0.25s ease;
    -webkit-overflow-scrolling: touch;
    --mega-menu-column-header-border: 0;
    --mega-menu-column-header-margin: 0 0 var(--spacer-base);
    --mega-menu-column-header-padding: 0;
    --mega-menu-content-padding: var(--spacer-sm) 0 var(--spacer-lg);
    --mega-menu-bar-display: none;
    --mega-menu-height: auto;

    &.ab25020 {
      top: 232px;
      height: 510px;
    }

    .display-block {
      display: block !important;
    }

    @include for-mobile {
      bottom: auto;
      height: calc(100dvh - 118px);

      // Target iOS Safari only
      @supports (-webkit-touch-callout: none) {
        height: var(--viewport-height, calc(100vh - 118px));
        padding-bottom: 118px;
      }
    }

    @include for-desktop {
      --mega-menu-content-padding: var(--spacer-base) var(--spacer-sm) var(--spacer-lg);
      --mega-menu-height: auto;
      position: absolute;
      top: unset;
      bottom: unset;
      margin-top: unset;
      overflow: auto;
      max-height: calc(100vh - 118px);
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include for-desktop {
        display: grid;
        grid-template-areas: 'suggestions products' 'content products';
        grid-template-columns: 240px 1fr;
        grid-template-rows: min-content 1fr;
      }
    }

    .sf-mega-menu__menu {
      @include for-desktop {
        margin-top: var(--spacer-base);
      }

      @include for-mobile {
        grid-column: 1 / -1;
      }
    }

    &__suggestions {
      margin-bottom: 0;
      --list-item-margin: 0 0 var(--spacer-10);
      list-style-type: none;

      @include for-mobile {
        order: 1;
        padding: var(--spacer-xs) var(--spacer-15);
      }

      @include for-desktop {
        grid-area: suggestions;
        --list-margin: 0 0 var(--spacer-lg);
      }

      &-list-item {
        button {
          display: inline-block;
          text-align: left;
        }

        &--show-all {
          position: absolute;
          right: 0;

          button {
            display: flex;
            text-decoration: underline;
          }
        }
      }
    }

    .sf-bar {
      display: none;
    }

    .product-card__wishlist-icon {
      display: none;
    }

    .sf-loader {
      min-height: 100px;

      @include for-desktop {
        min-height: 404px;
      }
    }
  }

  .show-all {
    text-align: center;
    margin-top: var(--spacer-xs);

    a {
      display: inline-block;
      line-height: 1;
    }
  }

  .action-buttons {
    padding: var(--spacer-xl) var(--spacer-sm) var(--spacer-3xl);
    background: var(--c-white);
    width: 100%;
    &__button {
      width: calc(100% - 32px);
    }
  }

  .result-card {
    margin: 0;
  }

  .before-results {
    box-sizing: border-box;
    padding: var(--spacer-base) var(--spacer-sm) var(--spacer-2xl);
    width: 100%;
    text-align: center;
    @include for-desktop {
      padding: 0;
    }

    &__picture {
      --image-width: 230px;
      margin-top: var(--spacer-2xl);
      @include for-desktop {
        --image-width: 18.75rem;
        margin-top: var(--spacer-base);
      }
    }

    &__paragraph {
      font-family: var(--font-family--primary);
      font-weight: var(--font-weight--normal);
      font-size: var(--font-size--base);
      line-height: 1.25;
      color: var(--c-black);
      margin: 0;

      strong {
        font-weight: var(--font-weight--semibold);
      }

      a {
        color: inherit;
        text-decoration: underline;

        &:hover {
          color: var(--cta-pink-color);
          text-decoration: underline;
        }
      }

      @include for-desktop {
        font-size: var(--font-size--lg);
      }

      &:first-of-type {
        margin: var(--spacer-xl) auto var(--spacer-xs);
      }
    }

    &__button {
      margin: var(--spacer-xl) auto;
      width: 100%;
    }
  }
}
</style>
