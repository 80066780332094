<script lang="ts" setup>
import type { Page, Component, Reference, Content } from '@bloomreach/spa-sdk'
import productGetters from '~/utils/getters/magentoProductGetters'
import type { Ref } from 'vue'
import type { ProductInterface, Products } from '@vue-storefront/magento-types'

interface Props {
  component: Component
  page: Page
}

const props = defineProps<Props>()

const { $encodeBase64 } = useNuxtApp()

const document = props.component && props.component.getModels()?.document
const content: Content | undefined = props.page.getContent(document)
const pageData = content?.getData()

const isPreview = props.page.isPreview()
const color: string = pageData?.color?.selectionValues[0]?.key
const title: string = pageData?.title
const buttonLabel: string = pageData?.buttonLabel
const externalLink: string = pageData?.ctaUrl
const internalLink: string | undefined = props.page?.getContent(pageData?.ctaLink)?.getUrl()

const category: string = $encodeBase64(pageData?.categoryManual)

type SortValue = {
  selectionValues: {
    key: string
    label: string
  }[]
  sourceName: string | null
}
const sortValue: SortValue = pageData?.categorySort
const skus: string[] = pageData?.productsManual
const productFilters: Content['getData'][] = pageData?.productFilters?.map(
  (item: Reference | string) => props.page?.getContent(item)?.getData() || {},
)

const cacheId = `products=${skus?.join(',') || ''}&category=${category || ''}`

const { getProductList } = useProduct(cacheId)
const localePath = useLocalePath()

const productsLoading = ref(true)
const products: Ref<ProductInterface[]> = ref([])

const setImageModelAsThumbnail = () => {
  products.value.forEach((product) => {
    const imageModelPosition = product.media_gallery_entries!.find((entry) =>
      entry!.types!.includes('image_model'),
    )?.position

    product.thumbnail!.url =
      product.media_gallery!.find((item) => item?.position === imageModelPosition)?.url || product.thumbnail!.url
  })
}

const getProductsList = async () => {
  // Check if anything is selected, otherwise bail.
  if (!skus?.length && !category?.length && !productFilters?.length) {
    return
  }

  productsLoading.value = true

  const searchQuery = {
    pageSize: category?.length ? 10 : 50,
    currentPage: 0,
    customQuery: {
      products: 'products',
      metadata: {},
    },
    filter: {
      ...getFilterStatement(skus, category, productFilters),
    },
    sort: {
      ...getSortStatement(sortValue?.selectionValues[0]?.key, skus?.[0] !== ''),
    },
  }

  const productsResult = await getProductList(searchQuery)

  products.value = productGetters
    .getFiltered(productsResult?.items, { master: true })
    .sort((a: ProductInterface, b: ProductInterface) => skus?.indexOf(a.sku!) - skus?.indexOf(b.sku!))

  setImageModelAsThumbnail()

  productsLoading.value = false
}

const showAddToCartButtons = ref(pageData?.addToCartButtons)

onMounted(getProductsList)

const getFilterStatement = (skus: string[], category: string, productFilters: Content['getData'][]) => {
  if (productFilters) {
    return createCustomFilter(productFilters)
  } else if (skus?.[0] !== '') {
    return {
      sku: {
        in: skus,
      },
    }
  } else {
    return {
      category_uid: {
        eq: category,
      },
    }
  }
}

const createCustomFilter = (productFilters: Content['getData'][]) => {
  const result = {}

  productFilters.map((filter) => {
    const key = filter.filterType.selectionValues[0].key
    let value = filter[key] ? filter[key].selectionValues[0].key : filter['value']
    if (key === 'category_uid') {
      value = $encodeBase64(value)
    }

    result[key] = {
      in: [value],
    }
  })

  return result
}

const getSortStatement = (sortValue: string, isSkuSearch: boolean) => {
  if (!isSkuSearch && sortValue && sortValue === 'priceAscending') {
    return { price: 'ASC' }
  } else if (!isSkuSearch && sortValue && sortValue === 'priceDescending') {
    return { price: 'DESC' }
  } else {
    return {}
  }
}
</script>

<template>
  <div :class="{ 'has-edit-button': isPreview }">
    <br-manage-content-button :content="content" />
    <div class="mj-product-carousel" :class="color === 'grey' ? 'mj-product-carousel__background-grey' : ''">
      <div class="container">
        <header v-if="title" class="mj-product-carousel__header">
          <h2>{{ title }}</h2>
        </header>
        <ProductsCarousel
          :products="products"
          :loading="productsLoading"
          :centerInsufficientSlides="true"
          :add-to-cart-buttons="showAddToCartButtons"
          :data-cache-id="cacheId"
        />
        <div v-if="buttonLabel" class="mj-product-carousel__button">
          <a
            v-if="externalLink"
            class="sf-button sf-button--hollow action primary link--external"
            :href="externalLink"
            :aria-label="buttonLabel"
          >
            {{ buttonLabel }}
          </a>
          <nuxt-link
            v-else
            class="sf-button sf-button--hollow action primary"
            :to="formatUrl(internalLink || '')"
            :title="buttonLabel"
            :aria-label="buttonLabel"
          >
            {{ buttonLabel }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
html.theme--storefront {
  @include for-mobile {
    .hst-container-item:has(.mj-product-carousel) {
      grid-column: 1 / -1;
    }
  }

  .mj-product-carousel {
    --carousel-width: 100%;
    padding-bottom: var(--spacer-xl);
    padding-top: var(--spacer-2xs);
    margin-bottom: var(--mj-component-margin-bottom);

    h2 {
      padding: 0 var(--spacer-sm);
      margin: var(--spacer-xs) 0;
    }

    @include for-mobile {
      padding-top: var(--spacer-xl);
    }

    .sf-section {
      --section-margin: 0;
      --section-content-margin: 0;

      @include for-desktop {
        --section-content-margin: var(--spacer-base);
      }

      @include for-mobile {
        .sf-heading {
          padding-bottom: 0;
        }

        .sf-heading__title {
          margin-bottom: 0;
        }
      }
    }

    .sf-carousel__controls {
      z-index: 1;
      pointer-events: none;

      .sf-button {
        pointer-events: initial;
      }
    }

    .sf-carousel-item {
      height: auto; /* Fixes flex stretch */
    }

    &__button {
      width: fit-content;
      margin: var(--spacer-lg) auto 0;
    }

    &__background-grey {
      background-color: var(--gray-background-color);

      .products-carousel {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}
</style>
