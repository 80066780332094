<script setup lang="ts">
const props = defineProps({
  faqRef: {
    type: String,
    default: '',
  },
})
const logger = useAppLogger('Fag')
const {
  $i18n: { locale },
} = useNuxtApp()
const { data: faqData } = useAsyncData(async () => {
  const ref = props.faqRef.replace('/page/', '')
  if (!ref) {
    logger.warn('FAQ reference is not provided')
    return {}
  }

  try {
    const faqResponse = await $fetch('/api/bloomreach/document', {
      query: {
        path: `/${ref}`,
        locale: locale.value,
      },
    })
    const faqItems = Object.values(faqResponse.content).map((faqItem: any) => faqItem.data)

    return {
      title: faqItems.find((item) => item.title)?.title,
      backgroundColor: faqItems.find((item) => item.background_color)?.background_color,
      questions: faqItems.filter((item) => item.question),
    }
  } catch (error) {
    logger.info(`Failed to fetch document data /${ref}`, error)
    return {}
  }
})
</script>

<template>
  <div class="faq-component">
    <SfHeading
      v-if="faqData?.title"
      class="sf-accordion__heading"
      :title="faqData?.title"
      :level="2"
      :style="faqData?.backgroundColor ? { backgroundColor: faqData?.backgroundColor } : {}"
    />
    <SfAccordion multiple showChevron transition="sf-collapse-top">
      <SfAccordionItem v-for="item in faqData?.questions" :key="item.id" :header="item.question">
        <div class="rich-text">
          <HTMLContent :content="item.answer.value" />
        </div>
      </SfAccordionItem>
    </SfAccordion>
  </div>
</template>

<style lang="scss" scoped>
.sf-heading {
  padding: var(--spacer-sm) 0 var(--spacer-xs);

  &__title.h2 {
    margin: 0;
    text-align: left;
  }
}
</style>
