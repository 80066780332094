<script setup lang="ts">
const props = defineProps({
  component: null,
  page: null,
})

const identifier = computed(() => {
  return props.component?.getProperties()?.id
})
const isPreview = computed(() => {
  return false
})
</script>

<template>
  <div v-if="component" :class="{ 'has-edit-button': isPreview }" :id="identifier">
    <noscript>Turn on JavaScript</noscript>
  </div>
</template>
