<script lang="ts" setup>
import type { Component as BrComponent, Page } from '@bloomreach/spa-sdk'

const { toggleMobileMenu } = useUiState()

const { useAb25019 } = useAbTest()

const { ab25019ShowV1 } = useAb25019()

const isSearchOpen = ref(false)

const {
  $i18n: { locale },
} = useNuxtApp()
const logger = useAppLogger('MobileNavMenu')

const props = defineProps({
  component: {
    type: Object as () => BrComponent,
    required: true,
  },
  page: {
    type: Object as () => Page,
    required: true,
  },
})

const { menu } = useMenu(props)
const ctaItems = ref([])
const uspItems = ref([])
const ctaUspsLoading = ref(false)
const ctaUspsLoaded = ref(false)

const fetchDocumentData = async (ref: string) => {
  const data = await $fetch('/api/bloomreach/document', {
    query: {
      path: ref,
      locale: locale.value,
    },
  })

  const headerLink =
    data?.content?.[data?.content[data?.document?.$ref?.substring(9)]?.data?.headerLink?.$ref?.substring(9)]?.links
      ?.site?.href || null

  const documentData = data?.content[data?.document?.$ref?.substring(9)]?.data
  return documentData ? { ...documentData, headerLink } : {}
}

const loadHeaderItems = async () => {
  ctaUspsLoading.value = true
  try {
    const items = await fetchDocumentData('/header/mobileheaderctausps')
    ctaItems.value = await Promise.all(
      items?.header1?.map((item) => item.$ref.substring(9)).map((ref) => fetchDocumentData(ref)),
    )
    uspItems.value = await Promise.all(
      items?.header2?.map((item) => item.$ref.substring(9)).map((ref) => fetchDocumentData(ref)),
    )
  } catch (error) {
    logger.error('Error', error)
  } finally {
    ctaUspsLoading.value = false
    ctaUspsLoaded.value = true
  }
}

const ab25019CloseSearchAndMenu = () => {
  isSearchOpen.value = false
  toggleMobileMenu()
}

onMounted(() => {
  loadHeaderItems()
})
</script>

<template>
  <ul v-if="menu" class="mobile-nav-menu" :class="{ 'has-edit-button': page.isPreview() }">
    <br-manage-menu-button :menu="menu" />
    <header
      class="mobile-nav-menu__header"
      :class="ab25019ShowV1 ? 'w-[calc(100%-var(--spacer-xl))] mt-(--spacer-sm)' : 'w-full'"
    >
      <span v-if="!ab25019ShowV1" class="mobile-nav-menu__header-title">Menu</span>
      <StoreSwitcher v-if="!ab25019ShowV1" class="store-switcher" />
      <SearchBar v-if="ab25019ShowV1" @SearchBar:toggle="isSearchOpen = $event" />
    </header>
    <MobileNavMenuItem
      v-for="(item, index) in menu?.getItems()"
      :key="index"
      :index="index + 1"
      :item="item"
      :level="1"
    />
    <MobileNavCtaItems :loading="ctaUspsLoading" :items="ctaItems" />
    <MobileNavUspItems :loading="ctaUspsLoading" :items="uspItems" />

    <ClientOnly>
      <SearchBarResults v-if="isSearchOpen" :visible="isSearchOpen" @SearchBar:hide="ab25019CloseSearchAndMenu" />
    </ClientOnly>
  </ul>
</template>

<style lang="scss">
.mobile-nav-menu {
  list-style-type: none;
  --search-bar-display: flex !important;

  > li {
    line-height: var(--xs-line-height);
    font-weight: var(--font-weight--semibold);
    padding: 0;
    margin: 0 0 var(--spacer-12);
    > .nav-link,
    > a {
      background-color: var(--gray-background-color);
      border-radius: var(--spacer-2xs);
    }
  }
  &__header {
    height: var(--spacer-xl);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacer-sm);
    &-title {
      font-family: var(--font-family--secondary);
      font-size: 22.4px;
      line-height: var(--global-line-height);
    }
  }
  .store-switcher {
    --button-height: var(--spacer-lg);
  }
}
</style>
