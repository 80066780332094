<script setup lang="ts">
import { getBynderAsset } from '~/utils/bynderImage'

interface Props {
  data?: String
  dataMobile?: String
  size?: String
  ratio?: Number
  ratioMobile?: Number
  alt?: String
  loading?: String
  transformation?: Symbol
}

const props = defineProps<Props>()

const asset = computed(() => {
  if (!props.data) return
  return getBynderAsset(props.data, props.dataMobile, props.size, props.ratio, props.ratioMobile, props.transformation)
})
const ratioPercentage = computed(() => {
  if (isMobile.value && props.ratioMobile) {
    return Math.round(100 * props.ratioMobile)
  }
  return props.ratio ? Math.round(100 * props.ratio) : null
})
const cssVars = computed(() => {
  if (!ratioPercentage.value) return
  return {
    '--padding-top': ratioPercentage.value + '%',
  }
})
const isMobile = false //@todo make dynamic

const getExtensionFromUrl = (url) => {
  return url?.split('.')?.pop()
}
</script>

<template>
  <picture
    v-if="asset && asset.type === 'IMAGE'"
    class="bynder-asset bynder-asset--image"
    :class="{ 'has-ratio': ratio }"
    :style="cssVars"
  >
    <source v-if="asset.srcsetMobile" media="(max-width: 1024px)" :srcset="asset.srcsetMobile" />
    <source
      v-if="asset.srcsetDesktop"
      :media="asset.srcsetMobile ? '(min-width: 1025px)' : null"
      :srcset="asset.srcsetDesktop"
    />
    <img
      :alt="alt || asset.title"
      :src="asset.thumbnailUrl"
      :height="asset.height"
      :width="asset.width"
      :loading="loading || 'lazy'"
    />
  </picture>
  <div
    v-else-if="asset && asset.type === 'VIDEO'"
    class="bynder-asset bynder-asset--video"
    :class="{ 'has-ratio': ratio }"
    :style="cssVars"
  >
    <video autoplay muted loop playsinline>
      <source :src="asset.url" :type="`video/${asset.extension || getExtensionFromUrl(asset.url) || 'mp4'}`" />
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<style lang="scss">
html.theme--storefront {
  .bynder-asset {
    display: flex;
    position: relative;

    img,
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &.has-ratio {
      &:before {
        content: '';
        display: block;
        padding-top: var(--padding-top);
        width: 100%;
      }

      img,
      video {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>
