<script setup lang="ts">
import type { Component, Document, Page } from '@bloomreach/spa-sdk'

interface Props {
  component: Component
  document?: Document
  page: Page
}

const props = defineProps<Props>()

const componentProperties = props.component?.getProperties()

const elementId = `qualifio_insert_place_${componentProperties?.id}`
const widgetId = componentProperties?.widgetId
const alignment = componentProperties?.alignment

onMounted(() => {
  ;(function (b, o, n, u, s) {
    let a, t
    a = b.createElement(u)
    a.async = 1
    a.src = s
    t = b.getElementsByTagName(u)[0]
    t.parentNode.insertBefore(a, t)
    o[n] = o[n] || []
  })(document, window, '_qual_async', 'script', 'https://files.qualifio.com/kit/qualp.2.min.js')
  _qual_async.push([
    'createIframe',
    elementId,
    'dachshowcases.qualifioapp.com',
    '20',
    widgetId,
    '100%',
    '1200',
    '',
    '',
    '',
    'max-width:810px;margin:0 auto;',
  ])
})

// 1496344
// 5A6A9E11-3F65-4B89-ADF4-9896B59FC0A9
</script>

<template>
  <div :id="elementId" class="qualifio_iframe_wrapper" :class="alignment"></div>
</template>

<style lang="scss" scoped>
.qualifio_iframe_wrapper {
  display: flex;

  ::v-deep(iframe) {
    margin: 0 !important;
  }

  &.left {
    justify-content: flex-start;
  }
  &.center {
    justify-content: center;
  }
  &.right {
    justify-content: flex-end;
  }
}
</style>
