<script setup lang="ts">
import type { Component, Page } from '@bloomreach/spa-sdk'
import { getBynderImageName, getSvgOrThumbnailImageUrl } from '@/utils/bynderImage'

interface Props {
  component: Component
  document?: Document
  page: Page
}

const props = defineProps<Props>()

const computedDocument = computed(() => {
  if (props.document) return props.document
  const document = props.component && props.component.getModels()?.document
  return props.page.getContent(document)
})
const isPreview = computed(() => {
  return props.page?.isPreview()
})
const data = computed(() => {
  return computedDocument.value?.getData() || {}
})

const items = computed(() => {
  const items = data.value?.logoBannerItems?.map((item) => props.page.getContent(item)?.getData() || {})
  return items
})

const title = computed(() => {
  return data.value?.title
})
</script>

<template>
  <div v-if="component" class="logoBanner" :class="{ 'has-edit-button': isPreview }">
    <br-manage-content-button :content="computedDocument" />
    <header v-if="title" class="logoBanner__header">
      <h2>{{ title }}</h2>
    </header>
    <div class="logoRow">
      <figure v-for="(item, index) in items" :key="index" class="logoColumn">
        <img
          :src="getSvgOrThumbnailImageUrl(item.logoBannerImage)"
          :alt="getBynderImageName(item.logoBannerImage)"
          loading="lazy"
        />
      </figure>
    </div>
  </div>
</template>

<style lang="scss">
html.theme--storefront {
  .logoBanner {
    text-align: center;
    margin-bottom: var(--mj-component-margin-bottom);
    padding-left: var(--spacer-xs);
    padding-right: var(--spacer-xs);

    > .logoRow {
      --column-gap: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      justify-content: space-around;
      flex-wrap: wrap;
      gap: 20px;
      height: 100%;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      > .logoColumn {
        display: flex;
        max-width: calc((100% - 60px) / 4);
        height: 100%;
        min-width: 120px;
        align-items: center;
        justify-content: center;
        margin: 0;

        @media screen and (max-width: 768px) {
          min-width: 280px;
          min-height: 75px;
        }

        > img {
          height: 100%;
          width: 100%;
          min-width: 120px;
          max-height: 40px;
          object-fit: contain;

          @include for-desktop {
            max-height: 100px;
            max-height: 75px;
          }
        }
      }
    }

    &__header {
      text-align: center;
      padding: 0 var(--spacer-sm);
      margin: var(--spacer-50px) 0;
    }

    svg {
      max-height: 42px;
      max-width: 140px;
      width: auto;
      height: 100%;
    }
  }
}
</style>
