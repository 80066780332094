import type { Component, Page, Content, MenuItem } from '@bloomreach/spa-sdk'

type Menu = Omit<Content, 'getData'> & { getItems: () => MenuItem[] }
export const useMenu = (props: { component: Component; page: Page }) => {
  const menuRef = props.component.getModels()?.menu
  const menu: Menu = menuRef && props.page.getContent(menuRef)

  return {
    menu,
  }
}

export default useMenu
