<script lang="ts" setup>
import ChevronDownIcon from '~/storefront/assets/icons/chevron-down.svg'
import type { Component, Page } from '@bloomreach/spa-sdk'

defineProps({
  component: {
    type: Object as () => Component,
    required: true,
  },
  page: {
    type: Object as () => Page,
    required: true,
  },
})

const { t } = useI18n()
const {
  $i18n: { locale },
} = useNuxtApp()
const lang = locale.value.substring(0, 2)

const isFrance = lang === 'fr'

const items = ref([
  {
    label: t('atelier.Breda'),
    value: '373708',
    isFrance: 2,
  },
  {
    label: t('atelier.Den Bosch'),
    value: '373093',
    isFrance: 3,
  },
  {
    label: t('atelier.Eindhoven'),
    value: '373709',
    isFrance: 4,
  },
  {
    label: t('atelier.Zwolle'),
    value: '373706',
    isFrance: 5,
  },
  {
    label: t('atelier.Antwerp'),
    value: '373710',
    isFrance: 0,
  },
  {
    label: t('atelier.Ghent'),
    value: '374075',
    isFrance: 1,
  },
])

const value = ref('')
const zenchefId = ref('')
const select = ref()

const sortedItems = computed(() => {
  if (isFrance) {
    return [...items.value].sort((a, b) => a.isFrance - b.isFrance)
  }
  return items.value
})

onBeforeRouteLeave(() => {
  removeZenchefWidget()
})

const addZenchefWidget = () => {
  const el = document.getElementsByTagName('script')[0]
  if (document.getElementById('zenchef-sdk') || el.parentNode == null) return
  const js = document.createElement('script')
  js.id = 'zenchef-sdk'
  js.src = 'https://sdk.zenchef.com/v1/sdk.min.js'
  el.parentNode.insertBefore(js, el)
}

const removeZenchefWidget = () => {
  const el = document.getElementById('zenchef-sdk')
  if (el) {
    el.remove()
  }
  const zenchefIframe = document.querySelector('iframe[src*="zenchef"]')
  if (zenchefIframe) {
    zenchefIframe.remove()
  }
}

const bookAtelier = () => {
  if (value.value) {
    zenchefId.value = value.value
    removeZenchefWidget()
    addZenchefWidget()
  }
}
</script>
<template>
  <div class="my-6 atelier-selector flex w-full justify-center">
    <label class="relative w-[360px] atelier-selector__label-wrapper" :for="select">
      <USelect
        v-model="value"
        class="w-full pt-5 atelier-selector__select"
        :items="sortedItems"
        :ui="{
          trailingIcon: 'group',
          itemTrailing: 'hidden',
        }"
      >
        <template #trailing>
          <ChevronDownIcon class="transition-transform duration-200 group-data-[state=open]:rotate-180" />
        </template>
      </USelect>
      <span
        class="pointer-events-none left-0 absolute px-2.5 transition-all peer-focus:top-1 peer-focus:text-(--ui-text-highlighted) peer-focus:text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-(--ui-text-dimmed) peer-placeholder-shown:top-3 peer-placeholder-shown:font-normal atelier-selector__placeholder"
        :class="{
          'top-1 text-xs': value,
          'top-[15px] text-sm': !value,
        }"
      >
        {{ $t('Select an atelier') }}
      </span>
    </label>
    <UButton class="rounded atelier-selector__booking-button" color="secondary" @click="bookAtelier">{{
      $t('Book your spot')
    }}</UButton>
  </div>
  <div class="zc-widget-config" :data-restaurant="zenchefId" :data-lang="lang" :data-open="0"></div>
</template>
<style lang="scss" scoped>
@include for-desktop {
  .atelier-selector {
    gap: 16px;
  }
  .atelier-selector__booking-button,
  :deep(.atelier-selector__select) {
    height: 60px;
  }
  .atelier-selector__placeholder {
    padding-top: 5px;
  }
}

@include for-mobile {
  .atelier-selector {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  .atelier-selector__label-wrapper {
    width: 100%;
    height: 48px;
  }
  .atelier-selector__booking-button,
  .atelier-selector__select {
    height: 48px;
  }
  .atelier-selector__placeholder {
    padding-top: 1px;
  }
}
</style>
