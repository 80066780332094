<script setup lang="ts">
import { getSpacingClassName } from '~/utils/spacing'
import { kebabize } from '~/utils/stringHelpers'
import { parseBloomreachInternalLinks } from '~/utils/urlHelpers'
import { AspectRatio, parseBynderImagesInHtml } from '~/utils/bynderImage'
import type { Component, Page, Document } from '@bloomreach/spa-sdk'

interface Props {
  component: Component
  document?: Document
  page: Page
}

const props = defineProps<Props>()

const dividerString = '&#60;READMORE&#62;' //<READMORE>
const buttonClassMapping = {
  'primary-black': 'black',
  'primary-white': 'white',
  conversion: 'pink',
  outlined: 'hollow',
  'text-button': 'text',
}

const wrapTables = (html) =>
  html.replaceAll('<table', '<div class="table-wrapper"><table').replaceAll('</table>', '</table></div>')

const computedDocument = computed(() => {
  if (props.document) return props.document

  const document = props.component && props.component.getModels()?.document
  return props.page.getContent(document)
})

const documentData = computed(() => {
  return computedDocument.value?.getData() || {}
})

const isPreview = computed(() => {
  return props.page?.isPreview()
})

const isInvisible = ref(true)

const htmlContent = computed(() => {
  const fullText = documentData.value?.richText?.value
  let textToShow = ''
  if (fullText?.includes(dividerString)) {
    if (isInvisible) {
      textToShow = fullText.substring(0, fullText.indexOf(dividerString))
    } else {
      textToShow = fullText.replaceAll(dividerString, '')
    }
  } else {
    textToShow = fullText
  }
  return wrapTables(parseBynderImagesInHtml(parseBloomreachInternalLinks(textToShow), aspectRatio))
})

const showButton = computed(() => {
  return documentData.value?.richText?.value?.includes(dividerString)
})

const params = computed(() => {
  return props.component?.model?.meta?.paramsInfo || {}
})

const isFullWidth = computed(() => {
  return documentData.value?.fullWidth
})

const backgroundColor = computed(() => {
  return documentData.value?.backgroundColor
})

const buttonType = computed(() => {
  return buttonClassMapping[kebabize(documentData.value?.buttonType?.selectionValues?.[0]?.key || 'black')]
})

const buttonLabel = computed(() => {
  return documentData.value?.buttonLabel
})

const buttonLink = computed(() => {
  return documentData.value?.ctaUrl || formatUrl(props.page.getContent(documentData.value?.ctaLink)?.getUrl())
})

const buttonIcon = computed(() => {
  return documentData.value?.buttonIcon
})

const buttonTarget = computed(() => {
  return documentData.value?.ctaUrl ? '_blank' : ''
})

const aspectRatio = computed(() => {
  return documentData.value?.aspectRatio?.selectionValues?.[0]?.key || AspectRatio.Original
})

const marginBottom = computed(() => {
  return getSpacingClassName(params?.marginBottom || '')
})

const styleAttr = computed(() => {
  let styles = {}

  if (backgroundColor.value) {
    styles['backgroundColor'] = backgroundColor.value
  }

  if (marginBottom.value) {
    styles['--mj-component-margin-bottom'] = `var(--margin-bottom-${marginBottom.value})`
  }

  return styles
})

const isDesktop = computed(() => true)

const showRichText = computed(() => {
  return (
    (isDesktop.value && !props.component?.getParameters()?.mobileOnly) ||
    (!isDesktop.value && !props.component?.getParameters()?.desktopOnly)
  )
})
</script>

<template>
  <div
    v-if="computedDocument && showRichText"
    class="rich-text"
    :class="{
      'has-edit-button': isPreview,
      'rich-text--full-width': isFullWidth,
      'rich-text--has-background-color': backgroundColor,
      ['rich-text--mb-' + marginBottom]: marginBottom,
    }"
    :style="styleAttr"
  >
    <br-manage-content-button :content="computedDocument" />

    <div v-html="htmlContent" />
    <button v-if="showButton" class="link sf-button sf-button--text" @click="isInvisible ^= true">
      {{ isInvisible ? $t('Read more') : $t('Read less') }}
    </button>
    <footer v-if="buttonLabel" class="rich-text__footer">
      <nuxt-link
        class="sf-button"
        :class="[`sf-button--${buttonType}`, buttonIcon && `has-icon`]"
        :to="buttonLink"
        :target="buttonTarget"
      >
        <span v-if="buttonIcon" v-dompurify-html:svg="buttonIcon" class="sf-button__icon" />
        <span>{{ buttonLabel }}</span>
      </nuxt-link>
    </footer>
  </div>
</template>

<style lang="scss">
html.theme--storefront {
  .rich-text {
    max-width: var(--container-width-lg, 1270px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--mj-component-margin-bottom);

    p,
    ul,
    ol,
    table {
      margin: 0 0 var(--spacer-sm);
      word-break: break-word;
    }

    &__footer {
      text-align: center;
      padding-top: var(--spacer-xs);

      .sf-button {
        display: inline-flex;
        text-overflow: ellipsis;
        overflow: hidden;
        width: auto;
        max-width: 100%;
        gap: 0;

        .sf-button__icon {
          min-width: 16px;
          margin-right: 16px;

          svg {
            display: block;
            max-width: 22px;
            max-height: 22px;
            height: auto;
          }
        }
      }
    }

    a:not(.sf-button) {
      text-decoration: underline;
      text-underline-offset: 2px;

      &:hover,
      &:focus {
        color: var(--primary-color);
      }
    }

    img {
      width: auto;
      height: auto;
      max-width: 100%;
    }

    > * {
      max-width: 768px;
    }

    &.rich-text--full-width {
      padding-top: 0;
      padding-bottom: 0;

      @media screen and (min-width: 769px) {
        padding-top: 0;
        padding-bottom: 0;
      }

      > * {
        max-width: unset;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &--mb-md {
      @media screen and (max-width: 768px) {
        margin-bottom: var(--spacer-12);
      }
    }

    h1,
    h2,
    h3,
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    &.rich-text--has-background-color {
      padding: var(--mj-richtext-padding, var(--spacer-md)) var(--spacer-15);

      h1,
      h2,
      h3 {
        &:first-child:last-child {
          margin: -0.75em 0;
        }
      }

      @media screen and (min-width: 769px) {
        padding-top: var(--mj-richtext-padding-top, var(--spacer-xl));
        padding-bottom: var(--mj-richtext-padding-bottom, var(--spacer-xl));
        padding-left: var(--spacer-base);
        padding-right: var(--spacer-base);
      }
    }

    .bynder-asset--image {
      justify-content: center;
    }

    a,
    .link {
      display: inline-block;
      text-underline-offset: 2px;
      text-decoration: underline;
      transition: all 0.2s ease;

      &:hover,
      &:focus {
        color: var(--cta-pink-color);
      }
    }

    .link {
      margin: 12px 0 var(--spacer-md);
      appearance: none;
      border: none;
      padding: 0;
      background: none;
      cursor: pointer;
    }

    .two-column-text {
      &.is-invisible {
        opacity: 0;
        height: 0;
        overflow: hidden;
      }
    }
  }
}
</style>
