<script setup lang="ts">
import { Form } from 'vee-validate'
import MailIcon from '~/storefront/assets/icons/mail.svg'
import useExponeaConstants from '~/utils/constants/exponea'
import type { Locale } from '~/types/locale'

const validationSchema = {
  newsletter: 'required|email',
}

const { component, page } = defineProps({
  component: null,
  page: null,
})

const content = computed(() => {
  return component?.model?.meta?.paramsInfo || {}
})

const isPreview = computed(() => {
  return page?.isPreview()
})

const customerStore = useCustomerStore()
const exponeaStore = useExponeaStore()
const { SEGMENTATION_FOOTER_SUBSCRIPTION_ID } = useExponeaConstants()
const {
  $i18n: { locale },
  $config: {
    public: { exponea },
  },
} = useNuxtApp()

const subscribed = computed(() => exponeaStore.getSegmentationById(SEGMENTATION_FOOTER_SUBSCRIPTION_ID))

const errors = ref([])
const success = ref(false)

const { projectToken } = exponea?.[locale.value as Locale] || {}

const submit = async (values) => {
  const body = {
    commands: [
      {
        name: 'crm/events',
        data: {
          customer_ids: {
            registered: values.newsletter,
          },
          company_id: projectToken,
          type: 'double_opt_in',
          properties: {
            action: 'transferred',
            email: values.newsletter,
            source: 'public_api',
            consent_list: [
              {
                action: 'accept',
                category: 'newsletter',
                valid_until: 'unlimited',
              },
              {
                action: 'accept',
                category: 'personalised_email',
                valid_until: 'unlimited',
              },
            ],
          },
        },
      },
    ],
  }

  try {
    await $fetch(`https://api.exponea.com/bulk`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(body),
    })
    onSucces()
    success.value = true
    errors.value = []
  } catch (error) {
    success.value = false
    errors.value = error
  }
}

const onSucces = () => {
  exponeaStore.getSegmentation({ id: SEGMENTATION_FOOTER_SUBSCRIPTION_ID })
  if (dataLayer) {
    dataLayer.push({
      event: 'GAEvent',
      eventCategory: 'Marketing Automation',
      eventAction: 'Contact Signup',
      eventLabel: 'inschrijving footer',
      eventValue: undefined,
    })
  }
}

onMounted(async () => {
  exponeaStore.getSegmentation({ id: SEGMENTATION_FOOTER_SUBSCRIPTION_ID })
})

customerStore.$subscribe(() => {
  if (customerStore.isLoggedIn) {
    exponeaStore.getSegmentation({ id: SEGMENTATION_FOOTER_SUBSCRIPTION_ID })
  }
})
</script>

<template>
  <div
    v-if="component && subscribed !== 'Subscribed'"
    class="footer-cta-subscription"
    :class="{ 'has-edit-button': isPreview }"
  >
    <h4>{{ content.title }}</h4>
    <p>{{ content.description }}</p>

    <Form
      id="newsletter-validate-detail"
      v-slot="{ meta }"
      class="form subscribe exponea-form"
      method="post"
      :validation-schema="validationSchema"
      @submit="submit"
    >
      <FormField :label="content.placeholderEmail" name="newsletter" type="text" autocomplete="email" />

      <button
        :id="content.identifier"
        class="action submit"
        :title="content.buttonLabel"
        type="submit"
        :aria-label="content.buttonLabel"
        :disabled="!meta.valid"
      >
        <MailIcon />
      </button>

      <div v-if="success" class="success bg-success--variant">{{ $t("Done. You've subscribed") }}</div>
    </Form>
  </div>
  <div v-else-if="component" class="footer-cta-subscription" :class="{ 'has-edit-button': isPreview }">
    <h4>{{ $t('A 10% discount on your next purchase?') }}</h4>
    <SfButton
      class="sf-button--pure sf-button--hollow button--refer-a-friend sf-button"
      :link="localePath('/give-5-get-10')"
      >{{ $t('Subscribe your friend!') }}</SfButton
    >
  </div>
</template>

<style lang="scss" scoped>
.footer-cta-subscription {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include for-desktop {
    justify-content: space-between;
    text-align: center;
  }

  > span {
    width: 100%;

    @include for-desktop {
      margin: auto 0 0;
    }
  }

  .form {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: left;

    :deep(.exponea-form__element),
    :deep(.sf-input) {
      position: relative;
      margin: 0;
    }

    input {
      border-color: var(--primary-color);

      @include for-desktop {
        min-height: 56px;
      }
    }
  }

  .submit {
    display: flex;
    position: absolute;
    top: 14px;
    right: 16px;
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;
    transition: all 0.2s ease;

    @include for-desktop {
      &:hover,
      &:focus {
        color: var(--cta-pink-color);
      }
    }

    svg {
      color: var(--black-color);
    }
  }

  .button--refer-a-friend {
    padding: var(--spacer-xs) var(--spacer-sm);
    font-size: var(--font-size--base);
  }

  .success {
    margin-top: var(--spacer-xs);
    padding: var(--spacer-xs) var(--spacer-sm);
  }
}
</style>
