<script setup lang="ts">
import type { Store } from '~/types/storeGrid'
import BynderAsset from '../BynderAsset.vue'
import { TransformationTypes } from '~/utils/bynderImage'

const props = defineProps({
  store: {
    type: Object as PropType<Store>,
    required: true,
  },
})

const localePath = useLocalePath()
const transformationTypes = computed(() => TransformationTypes)

const asset = computed(() => {
  return {
    data: props.store?.document.model.data.storeImage,
    size: '(min-width: 1024px) 300px, 50vw',
    ratio: 1,
  }
})
</script>

<template>
  <article v-if="store" class="piercing-store-grid-item">
    <nuxt-link class="piercing-store-grid-item__link" :to="localePath(store.bookingUrl)">
      <BynderAsset
        class="piercing-store-grid-item__asset"
        :data="asset.data"
        :size="asset.size"
        :ratio="asset.ratio"
        :alt="`${store.address.streetName} ${store.address.city}`"
        :transformation="transformationTypes.FIT"
      />
      <div class="piercing-store-grid-item__body">
        <h2 class="piercing-store-grid-item__body-title h3">
          <span class="piercing-store-grid-item__prefix">{{ $t('Ear piercing in') }}</span>
          <span class="piercing-store-grid-item__location">
            <span class="piercing-store-grid-item__location-city">{{ store.address.city }}</span>
            <span v-if="store.displayStreetName" class="piercing-store-grid-item__location-street">{{
              store.address.streetName
            }}</span>
          </span>
        </h2>
      </div>
    </nuxt-link>
    <br-manage-content-button :content="store.document" />
  </article>
</template>

<style lang="scss">
html.theme--storefront {
  .piercing-store-grid-item {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;

    &__link {
      display: block;
      flex: 1;
      border-radius: var(--spacer-2xs);
      background-color: var(--white-color);
      transition: all 0.2s ease;
      padding: var(--spacer-2xs);

      @include for-desktop {
        padding: var(--spacer-xs);
      }

      &:hover,
      &:focus {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }

    &__body {
      padding: var(--spacer-2xs);

      @include for-desktop {
        padding: var(--spacer-sm) var(--spacer-lg) var(--spacer-xs);
      }

      h2 {
        margin-bottom: 0;
      }
    }

    &__prefix {
      display: block;
      font-size: var(--font-size-10);
      font-weight: var(--font-weight--normal);
      color: var(--cta-pink-color);
      line-height: var(--spacer-18);

      @include for-desktop {
        font-size: var(--font-size-13);
        margin-bottom: var(--spacer-2xs);
      }
    }

    &__location {
      display: block;
      font-size: var(--h3-font-size);
      line-height: var(--global-line-height);

      @include for-desktop {
        line-height: 1.2381; // var(--sm-line-height);
      }

      &-street {
        font-size: var(--font-size-sm);

        @include for-desktop {
          font-size: inherit;
        }
      }

      > span {
        display: block;
      }
    }

    .bynder-asset {
      img,
      video {
        object-fit: contain;
      }
    }
  }
}
</style>
