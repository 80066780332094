<script setup lang="ts">
const props = defineProps({
  id: {
    type: String,
    default: '0cfe14ca-6faa-4a90-bd54-428628f903db',
  },
  image: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'footer',
    validator: (value: string) => {
      return ['inline', 'footer'].includes(value)
    },
  },
})

const widget = ref()
const score = ref()
const url = ref()

const setScript = () => {
  if (!widget.value) return
  const feedbackScript = document.createElement('script')
  feedbackScript.setAttribute('defer', 'defer')
  feedbackScript.setAttribute('charset', 'utf-8')
  feedbackScript.setAttribute('id', `__fbcw__${props.id}`)

  feedbackScript.innerHTML = `
        "use strict";
        !(function () {
          window.FeedbackCompanyWidgets = window.FeedbackCompanyWidgets || {
            queue: [],
            loaders: [],
          };
          var options = {
            uuid: "${props.id}",
            version: "1.2.1",
            prefix: "",
          };
          if (void 0 === window.FeedbackCompanyWidget) {
            if (
              (window.FeedbackCompanyWidgets.queue.push(options),
              !document.getElementById("__fbcw_FeedbackCompanyWidget"))
            ) {
              var scriptTag = document.createElement("script");
              (scriptTag.onload = function () {
                if (window.FeedbackCompanyWidget)
                for (; 0 < window.FeedbackCompanyWidgets.queue.length; )
              (options = window.FeedbackCompanyWidgets.queue.pop()),
            window.FeedbackCompanyWidgets.loaders.push(
              new window.FeedbackCompanyWidgetLoader(options)
            );
          }),
          (scriptTag.defer = true),
          (scriptTag.id = "__fbcw_FeedbackCompanyWidget"),
          (scriptTag.src =
          "https://www.feedbackcompany.com/includes/widgets/feedback-company-widget.min.js"),
          document.body.appendChild(scriptTag);
        }
      } else
      window.FeedbackCompanyWidgets.loaders.push(
        new window.FeedbackCompanyWidgetLoader(options)
      );
    })();
    `

  widget.value.appendChild(feedbackScript)

  window.checkElement('.__fbcw__widget').then((element) => {
    url.value = element.href
    score.value = element.querySelector('.__fbcw__widget__label')?.textContent?.split('/')[0]?.trim()
  })
}

onMounted(() => {
  setScript()
})
</script>

<template>
  <div class="feedback-company-wrapper" :class="{ 'feedback-company-wrapper--inline': type === 'inline' }">
    <div v-show="type !== 'inline'" class="feedback-company">
      <div ref="widget" class="feedback-company-widget" :data-id="id"></div>
    </div>

    <a v-if="type === 'inline' && score" :href="url" target="_blank">
      ✨ {{ score }}/10 score <strong>Feedback Company</strong>
    </a>

    <div v-if="type !== 'inline'" class="shopping-award">
      <BynderAsset :data="image" size="(min-width: 992px) 88px, 65px" />
    </div>
  </div>
</template>

<style lang="scss">
html.theme--storefront {
  .feedback-company-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 16px;

    @include for-desktop {
      justify-content: flex-start;
      margin-bottom: var(--spacer-sm);
    }

    &--inline {
      margin-bottom: 0;
    }
  }

  .feedback-company-wrapper .shopping-award {
    display: flex;
  }

  .feedback-company-wrapper .shopping-award img {
    width: 65px;
  }

  .feedback-company-wrapper .shopping-award .shopping-award {
    display: block;
  }

  .feedback-company-wrapper .feedback-company {
    margin-right: 12px;
  }

  .feedback-company-wrapper .feedback-company .__fbcw__widget.__fbcw__widget--small {
    width: 135px !important;
    border: none !important;
    background-color: transparent !important;
  }

  .feedback-company-wrapper .feedback-company .__fbcw__widget .__fbcw__widget__footer {
    background: transparent !important;
  }

  @media only screen and (min-width: 992px) {
    .feedback-company-wrapper .shopping-award img {
      width: 88px;
    }

    .feedback-company-wrapper .feedback-company .__fbcw__widget.__fbcw__widget--small {
      width: 200px !important;
    }
  }
}
</style>
