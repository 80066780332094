<script setup lang="ts">
import type { Component, Page } from '@bloomreach/spa-sdk'
import TimesIcon from '~/storefront/assets/icons/times-light.svg'

interface Props {
  component: Component
  page: Page
}

const props = defineProps<Props>()

const content = computed(() => {
  const document = props.component && props.component.getModels()?.document
  return props.page.getContent(document)
})

const model = computed(() => content.value?.getData())
const isPreview = computed(() => props.page?.isPreview())
const title = computed(() => model.value?.title)
const message = computed(() => model.value?.message)
const type = computed(() => model.value?.type?.selectionValues[0]?.key)

const isVisible = ref(false)

const checkIfVisible = () => {
  const itemFromStorage = sessionStorage.getItem('closedInlineNotifications')
  const currentClosedInlineNotifications = itemFromStorage ? JSON.parse(itemFromStorage) : []
  const isClosed = currentClosedInlineNotifications.includes(model.value?.id)
  isVisible.value = !isClosed
}

const close = () => {
  const itemFromStorage = sessionStorage.getItem('closedInlineNotifications')
  const currentClosedInlineNotifications = itemFromStorage ? JSON.parse(itemFromStorage) : []
  const closedInlineNotifications = [...currentClosedInlineNotifications, model.value?.id]
  sessionStorage.setItem('closedInlineNotifications', JSON.stringify(closedInlineNotifications))
  isVisible.value = false
}

onMounted(() => {
  checkIfVisible()
})
</script>

<template>
  <div v-if="component" :class="isPreview ? 'has-edit-button' : ''">
    <br-manage-content-button :content="content" />
    <SfNotification :visible="isVisible" :title="title" :message="message" :type="type">
      <template #title="{ title }">
        <div class="sf-notification__title">{{ title }}</div>
      </template>
      <template #close>
        <SfButton
          class="sf-button--pure sf-notification__close sf-button"
          :aria-label="$t('Close notification')"
          @click="close"
        >
          <TimesIcon />
        </SfButton>
      </template>
    </SfNotification>
  </div>
</template>
