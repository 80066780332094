export const generateRandomId = () => {
  const array = new Uint32Array(2)
  crypto.getRandomValues(array)
  return (
    'id-' +
    Array.from(array)
      .map((n) => n.toString(16))
      .join('')
  )
}
