import { type MenuItem, TYPE_LINK_EXTERNAL } from '@bloomreach/spa-sdk'
import { clickOutside } from '~/storefront/components/Sf/utilities/directives'

export const useMenuNested = () => {
  const vClickOutside = clickOutside

  const isExpanded = ref(false)
  const localePath = useLocalePath()

  const toggleExpanded = () => {
    isExpanded.value = !isExpanded.value
  }

  const getClassName = (item: MenuItem) => {
    return {
      expanded: isExpanded.value,
      'has-children': item.getChildren()?.length,
      active: item.isSelected(),
    }
  }

  const getLinkClassName = (item: MenuItem) => {
    return {
      'nav-link--disabled': !item.getUrl(),
      'nav-link--external': item.getLink()?.type === TYPE_LINK_EXTERNAL,
      'nav-link--expandable': item.getChildren()?.length,
    }
  }

  const closeNav = () => {
    isExpanded.value = false
  }

  const getUrl = (item: MenuItem) => item.getUrl()

  const getName = (item: MenuItem) => item.getName()

  const getChildren = (item: MenuItem) => item.getChildren()

  const getChildrenLength = (item: MenuItem) => item.getChildren()?.length

  return {
    vClickOutside,
    isExpanded,
    localePath,
    toggleExpanded,
    getClassName,
    getLinkClassName,
    closeNav,
    getUrl,
    getName,
    getChildren,
    getChildrenLength,
  }
}

export default useMenuNested
