<script setup lang="ts">
import type { Document, Page, Component } from '@bloomreach/spa-sdk'
import type { Store, StoreOpeningHours } from '~/types/storeGrid'

const props = defineProps({
  component: {
    type: Object as () => Component,
    required: true,
  },
  page: {
    type: Object as () => Page,
    required: true,
  },
})

const { pagination: paginationRef } = props.component.getModels()
const pagination = paginationRef && props.page.getContent(paginationRef)

const documents = pagination?.getItems().map((ref) => props.page.getContent(ref)) || []

const stores: Store[] = documents.map((doc) => {
  const {
    storeName,
    storeImage,
    displayName,
    streetName,
    houseNumber,
    addition,
    postalCode,
    city,
    country,
    bookingUrl,
    bookingImage,
    openingHours: openingHoursRefs,
    adjustedOpeningHours: adjustedOpeningHoursRefs,
  } = doc.getData()
  const openingHours: StoreOpeningHours[] = openingHoursRefs
    .map((ref) => props.page.getContent(ref))
    .filter((x) => !!x)
    .map((doc: Document) => {
      const { day, hours, note } = doc.getData() || {}
      return { day, hours, note }
    })

  const adjustedOpeningHours: StoreOpeningHours[] = adjustedOpeningHoursRefs
    .map((ref) => props.page.getContent(ref))
    .filter((x) => !!x)
    .map((doc: Document) => {
      const { day, hours, note } = doc.getData() || {}
      return { day, hours, note }
    })

  return {
    name: displayName,
    address: {
      streetName,
      houseNumber,
      addition,
      postalCode,
      city,
      country,
    },
    storeName,
    storeImage: getBynderImageUrl(storeImage),
    storeUrl: doc.getUrl(),
    bookingUrl,
    bookingImage,
    openingHours,
    adjustedOpeningHours,
    document: doc,
  }
})
</script>

<template>
  <div class="container">
    <section class="store-grid">
      <BloomreachStoreGridItem v-for="(store, index) in stores" :key="index" :store="store" />
    </section>
  </div>
</template>

<style lang="scss">
html.theme--storefront {
  .store-grid {
    position: relative;
    margin-bottom: var(--spacer-base);

    @include for-desktop {
      display: grid;
      column-gap: var(--spacer-md);
      row-gap: var(--spacer-lg);
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: var(--spacer-xl);
      width: calc(100% - (var(--spacer-md) * 2));
    }
  }
}
</style>
