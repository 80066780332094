<script lang="ts" setup>
import type { Component, Document, Page } from '@bloomreach/spa-sdk'

interface Props {
  component?: Component
  document?: Document
  page?: Page
}

const props = defineProps<Props>()
const { isMobile } = useDevice()
const componentProperties = props.component?.getProperties()
const zenchefId = componentProperties?.zenchefId

const {
  $i18n: { locale },
} = useNuxtApp()
const lang = locale.value.substring(0, 2)

onMounted(() => {
  const el = document.getElementsByTagName('script')[0]
  if (document.getElementById('zenchef-sdk') || el.parentNode == null) return
  const js = document.createElement('script')
  js.id = 'zenchef-sdk'
  js.src = 'https://sdk.zenchef.com/v1/sdk.min.js'
  el.parentNode.insertBefore(js, el)
})

onBeforeRouteLeave(() => {
  const el = document.getElementById('zenchef-sdk')
  if (el) {
    el.remove()
  }
  const zenchefIframe = document.querySelector('iframe[src*="zenchef"]')
  if (zenchefIframe) {
    zenchefIframe.remove()
  }
})
</script>

<template>
  <div class="zc-widget-config" :data-restaurant="zenchefId" :data-lang="lang" :data-open="isMobile ? '' : 0"></div>
</template>
